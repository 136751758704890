@media screen and (orientation:landscape) {
  #user-dropdown{
    margin-top: 5px;
  }
  .header .headline{
    margin: initial;
  }
  .main-container{
    padding-bottom: 45px;
    .home-page{
      img{
        max-height: 57vh;
      }
    }
  }
}

