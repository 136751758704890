.level-page{
  .image-container{
    img{
      max-width: 350px;
    }
  }
  .levels-container{
    margin: 20px;
    .btn{
      width: 100%;
      font-family: "Raleway",sans-serif;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .level-btn{
      font-weight: 100;
      font-size: 26px;
      line-height: 55px;
      margin-top: 20px;
      .fa{
        font-size: 18px;
      }
    }
    .artist-list{
      .btn{
        margin-top: 10px;
        font-size: 18px;
      }
      .score{
        float: right;
      }
    }
  }
  @media (max-width: 576px) {
    .image-container{
      img{
        max-width: 250px;
      }
    }
  }
}
