.auth-page{
  .btn-link{
    color: #ffffff;
    cursor: pointer;
    margin-top: 10px;
    display: block;
  }
  .forgot-link{
    display: inline;
    margin-left: 10px;
  }
  .social-button{
    button, span{
      width: 188px;
      font-size: 12px;
    }
  }
}
