.profession-painting{
  padding-bottom: 85px !important;
  .image-courtesy{
    display: block;
  }
}
.image-courtesy {
  display: none;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 10px;
  a{
    color: #f5f5f5;
  }
}
.footer-text{
  border-top: 1px solid #919191;
  display: flex;
  color: #919191;
  ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    li{
      float: left;
      margin-right: 5px;
      a{
        color: #919191;
        text-decoration: underline;;
      }
    }
  }
}
