.image-option{
  h3{
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 20px;
    color: #f5f5f5;
  }
}
