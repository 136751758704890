.game-page{
  .container{
    max-width: 100%;
  }
  &.music{
    .game-option{
      &.selected{
        &:hover{
          .artwork-action, .artwork-details{
            .action.hint{
              display: none;
            }
          }
        }
      }
      &.failed, &.selected{
        .icon-failed, .icon-selected{

        }
        &:hover{
          .artwork-action, .artwork-details{
            .action.check{
              display: none;
            }
            display: flex !important;
          }
        }
      }
    }
  }
  &.painting{
    .game-option{
      &.failed, &.selected{
        &:hover{
          .artwork-action, .artwork-details{
            .action.check{
              display: none;
            }
          }
        }
      }
    }

  }
  .game-status{
    #back-link{
      padding: 0 5px;
    }
    font-size: 22px;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #000000;
    margin-bottom: 10px;
    display: flex;
    .lives{
      margin-top: 5px;
      width: 125px;
      .lives-hearts, span{
        color: #f00;
        margin-right: 1px;
      }
    }
    .count-down-container{
      margin-right: 5px;
      margin-top: -2px;
    }
    .game-title{
      text-align: center;
      flex-grow: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .score{
      margin-right: 5px;
      width: 70px;
      .score-point{
        margin-left: 5px;
      }
    }
    .count-down-container{
      div{
        margin: auto;
      }
    }
    #user-dropdown {
      margin: 5px;
    }
  }
  .game-content{
    margin-bottom: 65px;
  }

  .game-option{
    text-align: center;
    padding-bottom: 7px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    .icon-loading{
      position: absolute;
      bottom: 3px;
      right: 47%;
      left: 47%;
    }
    .icon-selected, .icon-failed{
      display: none;
      position: absolute;
      right: 8px;
      bottom: 10px;
      z-index: 999;
      .fa{
        font-size: 20px;
      }
    }
    &.failed, &.selected{
      opacity: .5;
    }
    &.failed{
      .icon-failed{
        display: block;
      }
    }
    &.selected{
      .icon-selected{
        display: block;
      }
    }
    .artwork-option{
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      margin-bottom: 3px;
      background: #000;
      cursor: pointer;
      img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .artwork-title{
      font-family: "Raleway",sans-serif;
      line-height: 18px;
      font-size: 12px;
      margin: 5px 0 2px 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .artwork-hint-result{
      z-index: 999;
      bottom: 0;
      left: 16px;
      right: 16px;
      background: #000;
      padding: 2px;
      position: absolute;
    }
    .artwork-details{
      white-space: nowrap;
      font-size: 12px;
      color: #d3d3d3;
      line-height: 16px;
    }
    .artwork-action{
      display: none;
      position: absolute;
      bottom: 0;
      justify-content: space-around;
      left: 16px;
      right: 16px;
      background: #000;
      .action{
        border-color: #adadad;
        width: 31%;
        padding: 0;
        border: 0;
        font-size: 18px;
        display: inline-block;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
          color: #333;
          background-color: #e6e6e6;
          width: 31%;
          padding: 0;
          border: 0 #adadad;
          font-size: 18px;
          display: inline-block;
          border-radius: 4px;
        }
      }
    }
    &:hover{
      transition: .3s;
      border-color: #fff;
      .artwork-action{
        display: flex;
      }
    }
  }

  .finish-options{
    justify-content: center;
    margin: 20px 0 40px;

    .btn{
      margin: 3px;
    }
  }
  .game-result{
    .game-status{
      height: 40px;
    }
    position: relative;
    h3, h2{
      color: #ffffff;
      text-align: center;
      max-width: 85%;
      margin: auto;
    }
  }
  .selected-artworks {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #000;
    z-index: 100;
    img{
      margin: 0 auto;
      height: 70px;
    }
    span{
      display: inline-block;
      line-height: 34px;
      border: 2px solid #eee;
      background: #777;
      color: #eee;
      margin: 10px auto;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-weight: bold;
      font-size: 20px;
    }
    #help-link {
      position: absolute;
      bottom: 18px;
      right: 20px;
      font-size: 30px;
      a{
        color: #f5f5f5;
      }
    }
  }

  @media (max-width: 575px) {
    .game-status{
      .lives{
        width: 40px;
      }
      .game-title{
        width: 150px;
      }
      .score{
        width: 45px;
        .fa{
          display: none;
        }
      }
    }
    .finish-options{
      display: block;
      margin-top: 65px;
      .btn{
        display: block;
        margin-bottom: 10px;
      }
    }
    .game-option{
      .artwork-option{
        padding-bottom: 100%;

      }
    }
  }

  @media (max-width: 768px){
    .game-result{
      h2, h3{
        max-width: 70%;
      }
    }
  }

  @media (min-width: 768px){
    .game-result{
      h2{
        max-width: 70%;
      }
    }
    .container {
      width: 750px;
    }
    .game-status{
      #back-link, #user-dropdown{
        width: 75px;
      }
    }
  }

  @media (min-width: 1200px){
    .container {
      width: 1170px;
    }
  }

  @media (min-width: 992px){
    .container {
      width: 970px;
    }
  }



}
