@media screen and (orientation:landscape) {
  #user-dropdown{
    margin-top: 5px;
  }
  #back-link{
    padding-top: 5px !important;
  }
  .header .headline{
    margin: initial;
  }
}

.header{
  .headline{
    font-family: "Raleway",sans-serif;
    font-weight: 100;
    font-size: 26px;
    text-align: center;
    line-height: 55px;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: .67em 0;
  }
  small{
    font-size: 65%;
    color: #777;
  }

  @media (max-width: 576px) {
    .headline{
      font-size: 18px;
      line-height: 70px;
    }
  }
}
#user-dropdown {
  margin-top: 27px;
  float: right;
  img{
    max-height: 30px;
    border-radius: 50%;
  }
}
#back-link {
  display: inline-block;
  font-size: 26px;
  padding-top: 29px;
  color: #f5f5f5;
  cursor: pointer;
}
